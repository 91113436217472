import { lazy } from 'react';

import { authRoles } from 'app/auth';
import ShippingLabelContent from '../e-commerce/order/Dialogs/ShippingLabelContent';

const ShipmentOrder = lazy(() => import('./shipments/ShipmentOrder'));
const CalendarApp = lazy(() => import('./calendar/CalendarApp'));
const Shipment = lazy(() => import('./shipment/Shipment'));
const PackingSlip = lazy(() => import('./shipment/PackingSlip'));
const Orders = lazy(() => import('../e-commerce/orders/Orders'));
const Shipments = lazy(() => import('./shipments/Shipments'));

const BackofficeConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.supplier,
  routes: [
    {
      path: 'shipments/:shipmentId',
      element: <ShipmentOrder />,
    },
    {
      path: 'shipments/receive/:shipmentId',
      element: <ShipmentOrder type="receive" />,
    },
    {
      path: 'shipments/calendar',
      element: <CalendarApp />,
      auth: authRoles.staff
    },
    {
      path: 'shipments/containers',
      element: <Orders type="container" for="container" />,
      auth: authRoles.staff
    },
    {
      path: 'outbound/shipments',
      element: <Shipments type="outbound"  />,
      auth: authRoles.staff
    },
    {
      path: 'outbound/print-label/:orderId',
      element: <ShippingLabelContent type="outbound" />,
      auth: authRoles.staff
    },
    {
      path: 'shipments/details/:shipmentId',
      element: <Shipment />,
      auth: authRoles.staff
    },
    {
      path: 'shipments/print-label/:orderId',
      element: <ShippingLabelContent />,
      auth: authRoles.staff
    },
    {
      path: 'shipments/packingSlip/:shipmentId/:orderId',
      element: <PackingSlip />,
      auth: authRoles.staff
    },
  ],
};

export default BackofficeConfig;
